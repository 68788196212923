import { compiledBlock as _compiledBlock } from "million/react";
const App_1 = _compiledBlock(() => <div className='status'>
				<h1>404 - PAGE NOT FOUND</h1>
				<p>
					The page you are looking for might have been removed had its name changed or it
					temporarily unavailable
				</p>
			</div>, {
  name: "App_1"
});
const App_2 = _compiledBlock(() => <div className='status'></div>, {
  name: "App_2"
});
const App_3 = _compiledBlock(_props3 => _props3.v0, {
  name: "App_3",
  portals: ["v0"]
});
const App = function App() {
  const [status, setStatus] = useState<Status>('LOADING');
  useEffect(() => {
    configuration.load().then(() => {
      setStatus('READY');
    }).catch(() => setStatus('FAILED'));
  }, []);
  if (status === 'FAILED') {
    return /*@million jsx-skip*/<App_1 />;
  }
  if (status === 'LOADING') {
    return /*@million jsx-skip*/<App_2 />;
  }
  return /*@million jsx-skip*/<App_3 v0={<BrowserRouter>
			<Routes>
				<Route path='/omise' element={<Suspense fallback={<div />}>
							<Omise conf={configuration} />
						</Suspense>} />
				<Route path='/auth' element={<Suspense fallback={<div />}>
							<Login />
						</Suspense>} />
			</Routes>
		</BrowserRouter>} />;
};
import { block as _block } from "million/react";
import './types';
import './style.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React, { Suspense, useEffect, useState } from 'react';
import { Configuration } from './configuration';
const Login = React.lazy(() => import('./apps/login'));
const Omise = React.lazy(() => import('./apps/omise'));
const configuration = new Configuration();
type Status = 'FAILED' | 'READY' | 'LOADING';
export default App;